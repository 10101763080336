<template>
  <div>
    <el-breadcrumb style="margin: 0 0 20px 10px" separator="/">
      <el-breadcrumb-item>餐单管理</el-breadcrumb-item>
      <el-breadcrumb-item>餐单日期</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">危废管理</div>
      <div class="xian"></div> -->
      <div class="list_box">
        <div class="tt1">日期列表</div>
        <div>
          <el-button
            size="small"
            type="add"
            @click="moban"
            >模板下载</el-button
          >
          <el-button
            size="small"
            type="add"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >导入</el-button
          >
        </div>
      </div>
      <div class="int_box">
        <div class="int_box11">
          <span>餐单日期：</span>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="productdate"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
          <el-button
            size="small"
            style="margin-left: 50px"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="userList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            width="60"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column
            label="餐单日期"
          >
          <template v-slot="scope">
            <div class="point" @click="jinru(scope.row.production_date)">{{scope.row.production_date}}</div>
          </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="120"
          >
            <template v-slot="scope">
              <el-button type="primary" size="mini" @click="jinru(scope.row.production_date)">查看菜品</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 20]"
          :page-size="pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- 添加菜品 -->
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="900px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <div style="max-height: 520px;max-width:800px; overflow: auto">
          <el-form
            ref="form"
            :rules="rules"
            :model="addform"
            label-width="140px"
          >
            <el-form-item label="菜品：" prop="img">
              <el-upload
                style="width: 500px"
                :limit="1"
                :before-upload="beforeAvatarUpload"
                :with-credentials="true"
                :action="URL + 'api/upload'"
                :headers="headers"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :on-exceed="handleExceed"
                :file-list="fileList"
              >
                <el-button size="small" type="primary">上传</el-button>
              </el-upload>
              <!-- <el-button
                v-if="addform.img"
                size="small"
                type="success"
                @click="yulan"
                >预览</el-button
              > -->
            </el-form-item>
            <el-form-item v-if="importinfo.length > 0">
              <table border="1" align="left" cellpadding="6" cellspacing="0">
                <tr v-for="(it, i) in importinfo" :key="i">
                  <template v-if="i == 0">
                    <th class="th1">序号</th>
                    <th class="th1" v-for="(t, e) in it" :key="e">{{ t }}</th>
                  </template>
                  <template v-if="i != 0">
                    <td class="th1">{{i}}</td>
                    <td class="th1" align="left" v-for="(t, e) in it" :key="e">{{ t }}</td>
                  </template>
                </tr>
              </table>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn_box">
          <el-button
            size="small"
            type="cha"
            class="add_btn111"
            @click="addWaste"
            >确定</el-button
          >
          <el-button size="small" @click="handleClose">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[3456789]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      page: 1,
      pageNumber: 10,
      productdate: '',
      userList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },
      dialogVisible: false,
      addform: {
        img: '',
      },
      rules: {
        img: [{ required: true, message: '请选择文件', trigger: 'change' }],
      },
      URL: '',
      headers: {
        token: '',
      },
      fileList: [],
      importinfo: [],
      text: ''
    }
  },
  created() {
    this.headers.token = window.localStorage.getItem('jd_token') || ''
    this.URL = this.$URL
    this.user()
  },
  methods: {
    handleSizeChange(val) {
      this.pageNumber = val
      this.user()
    },
    handleCurrentChange(val) {
      this.page = val
      this.user()
    },
    handleClose() {
      this.dialogVisible = false
      if (this.addform.img) {
        this.delWaste()
      }
      this.$refs.form.resetFields()
      this.fileList = []
    },
    // 列表
    async user() {
      const { data: res } = await this.$http.get('api/goods/productdate', {
        params: {
          page: this.page,
          size: this.pageNumber,
          productdate: this.productdate,
        },
      })
      this.total = res.data.total
      this.userList = res.data.rows
    },
    // 查询
    chaxun() {
      this.page = 1
      this.user()
    },
    jinru(time) {
      this.$router.push('/product?time=' + time)
    },
    // 点击添加按钮，弹出对话框
    add() {
      this.dialogVisible = true
      this.text = '添加'
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 5MB!')
      }
      return isLt2M
    },
    handleSuccess(res) {
      this.addform.img = res.data
      this.yulan()
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    handleRemove(file, fileList) {
      this.delWaste()
    },

    // 预览
    async yulan() {
      const { data: res } = await this.$http.get('api/goods/importinfo', {
        params: {
          name: this.addform.img,
        },
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.importinfo = res.data
    },
    // 模板下载
    moban() {
      window.open('/demo.xlsx')
    },
    // 添加
    addWaste() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        this.isLoading = true
        const { data: res } = await this.$http.get('api/goods/importproduct', {
          params: {
            name: this.addform.img,
          },
        })
        this.isLoading = false
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        this.fileList = []
        this.importinfo = []
        this.addform = {
          img: '',
        }
        this.page = 1
        this.user()
      })
    },
    // 删除excel
    async delWaste() {
      const { data: res } = await this.$http.get('api/goods/delexcel', {
        params: {
          name: this.addform.img,
        },
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.addform.img = ''
      this.importinfo = []
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box {
    padding: 20px 1px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .int_box11 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .int_width {
    width: 240px;
    margin-right: 40px;
  }
  .int_width1 {
    width: 300px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .int {
    width: 400px;
  }

  .add_btn {
    margin-left: 800px;
  }
  .danger_btn {
    margin-left: 20px;
  }
  .btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .add_btn111 {
    // width: 140px;
  }
  .point {
   cursor: pointer;
 }
 .th1 {
   white-space: nowrap;
 }
}
</style>
